"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.attachManual = void 0;
var helpers_1 = require("../common/helpers");
var utils_1 = require("../common/tracker/utils");
function attachManual() {
    var _a, _b, _c, _d;
    var wizard = (0, helpers_1.getDataElementValue)(["idp", "login", "wizard"]);
    switch (wizard) {
        case "username": {
            (_a = document
                .querySelector('input[name="username-username"]')) === null || _a === void 0 ? void 0 : _a.addEventListener("click", function () { return (0, utils_1.trackEvent)("IDP_login", "username_click"); });
            break;
        }
        case "auth": {
            (_b = document
                .querySelector('input[name="auth-password"]')) === null || _b === void 0 ? void 0 : _b.addEventListener("click", function () { return (0, utils_1.trackEvent)("IDP_login", "password_click"); });
            break;
        }
        case "token": {
            (_c = document
                .querySelector('input[name="token-otp_token"]')) === null || _c === void 0 ? void 0 : _c.addEventListener("click", function () { return (0, utils_1.trackEvent)("IDP_enter_otp", "token_click"); });
            (_d = document
                .querySelector('input[name="token-otp_trust_agent"]')) === null || _d === void 0 ? void 0 : _d.addEventListener("change", function (event) {
                var elem = event.target;
                if (!(elem && elem instanceof HTMLInputElement))
                    return;
                (0, utils_1.trackEvent)("IDP_enter_otp", "do_not_ask_" + (elem.checked ? "check" : "uncheck"));
            });
            break;
        }
    }
}
exports.attachManual = attachManual;
