"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.armLoginCooldown = void 0;
var helpers_1 = require("../common/helpers");
function armLoginCooldown() {
    var cooldown = parseInt((0, helpers_1.getDataElementValue)(["idp", "login", "cooldown"]) || "0");
    if (cooldown <= 0)
        return;
    var cooldownHtml = "\n    Try again in&nbsp;\n    <i class=\"far fa-clock\"></i>\n    <span class=\"timer\">".concat((0, helpers_1.formatCooldownTimer)(cooldown), "</span>\n  ");
    var passwordField = document.getElementById("id_auth-password");
    document.querySelectorAll(".login-cooldown-target").forEach(function (button) {
        var originalText = button.textContent;
        button.classList.add("white-veil");
        button.setAttribute("disabled", "disabled");
        button.innerHTML = cooldownHtml;
        passwordField === null || passwordField === void 0 ? void 0 : passwordField.setAttribute("disabled", "disabled");
        var state = { cooldown: cooldown };
        var done = function () {
            button.classList.remove("white-veil");
            button.removeAttribute("disabled");
            button.textContent = originalText;
            passwordField === null || passwordField === void 0 ? void 0 : passwordField.removeAttribute("disabled");
        };
        var timerContainer = button.querySelector(".timer");
        var interval = setInterval(function () {
            state.cooldown--;
            if (timerContainer)
                timerContainer.textContent = (0, helpers_1.formatCooldownTimer)(state.cooldown);
            if (state.cooldown === 0) {
                clearInterval(interval);
                done();
            }
        }, 1000);
    });
}
exports.armLoginCooldown = armLoginCooldown;
